import React from "react";
import * as Styled from "./PromoCarousel.styled";
import { ButtonOrange } from "../../elements/Buttons";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";

function SlideTextImage({ slideItem }) {
  return (
    <Styled.ContentWrapper>
      <Styled.PromoContent>
        <Styled.TitleBadgeWrapper>
          <Styled.TitleBadge>{slideItem.titleBadge}</Styled.TitleBadge>
        </Styled.TitleBadgeWrapper>
        <Styled.PromoContentTitle1 color={"#FF874F"}>
          {slideItem.vehicleTitle1}
        </Styled.PromoContentTitle1>
        <Styled.PromoContentTitle2 color={"#ffffff"}>
          {slideItem.vehicleTitle2}
        </Styled.PromoContentTitle2>
        <Styled.PromoContentSubTitle>
          {slideItem.vehicleSubTitle}
        </Styled.PromoContentSubTitle>
        <ButtonOrange
          labelCTA="Get a quote"
          onClick={SendUTMQueryParams}
        />
        <Styled.PromoContentDisclaimerText>
          {slideItem.disclaimerText}
          {slideItem.termsLinkUrl && (
            <Styled.PromoContentTermsLink
              href={slideItem.termsLinkUrl}
              target="_blank"
            >
              *T&Cs Apply
            </Styled.PromoContentTermsLink>
          )}
        </Styled.PromoContentDisclaimerText>
      </Styled.PromoContent>
      <picture>
        <source
          media="(max-width: 799px)"
          srcSet={`${slideItem.imageMobile}`}
        />
        <source media="(min-width: 800px)" srcSet={`${slideItem.image}`} />
        <Styled.VehicleImg src={slideItem.image} alt={slideItem.vehicleTitle} />
      </picture>
    </Styled.ContentWrapper>
  );
}

export default SlideTextImage;
