import React from 'react';
import * as Styled from "./BestDealBanner.styled";
import { ButtonOrange } from "../../elements/Buttons";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";

function BestDealBanner() {
  return (
    <Styled.MaxWidth>
      <Styled.Background>
        <Styled.MainWrapper>
          <Styled.ContentWrapper>
            <Styled.TitleBadge>Save $1000's with a novated lease</Styled.TitleBadge>
            <Styled.Title>Get the best deal on a novated lease</Styled.Title>
            <ButtonOrange onClick={SendUTMQueryParams} labelCTA="Get a quote" /> 
          </Styled.ContentWrapper>
          <Styled.ImageWrapper>
            <Styled.BestDealsBg />
          </Styled.ImageWrapper>
        </Styled.MainWrapper>
      </Styled.Background>
    </Styled.MaxWidth>
  );
}

export default BestDealBanner;