import React, { forwardRef } from "react";
import * as Styled from "./AboutNovatedLease.styled";
import Vimeo from '@u-wave/react-vimeo';
import { ButtonOrange } from "../../elements/Buttons";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";

const AboutNovatedLease = forwardRef((props, ref) => {

  return (
    <Styled.Background ref={ref} id="about">
      <Styled.MaxWidth>
        <Styled.Title>What is a novated lease?</Styled.Title>
        <Styled.VideoWrapper>
          <Vimeo
              video="918132024"
              autoplay={false}
              color='6E42F7'
              style={{ margin: '60px 0 30px 0' }}
              showTitle={false}
              responsive={true}
            />
        </Styled.VideoWrapper>
        <Styled.ContentText>
          <Styled.ContentTextBody>
            A novated lease is a cost-effective way to acquire and run your car. 
            You get an immediate discount on your new car, as <span className="highlight">you do not have to pay 
            GST on the purchase price of the car </span>- instantly saving you thousands of dollars. 
            You can also include all of the running costs of the car, <span className="highlight">including fuel or 
            electricity, registration, maintenance and insurance</span>. These running costs are 
            paid excluding GST and from your pre-tax salary, which reduces the income tax you 
            pay, leading to huge savings with a novated lease.
          </Styled.ContentTextBody>
        </Styled.ContentText>
        <Styled.ButtonWrapper>
          <ButtonOrange onClick={SendUTMQueryParams} labelCTA="Get a quote" />
        </Styled.ButtonWrapper>
      </Styled.MaxWidth>
    </Styled.Background>
  );
});

export default AboutNovatedLease;
