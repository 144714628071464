import PromoTesla from "../../../images/promo/PromoTesla.png";
import PromoTeslaMobile from "../../../images/promo/PromoTeslaMobile.png";
import PromoBYDShark from "../../../images/promo/PromoBYDShark.png";
import PromoBYDSharkMobile from "../../../images/promo/PromoBYDSharkMobile.png";
import PromoMitsubishOut from "../../../images/promo/PromoMitsubishiOutlander.png";
import PromoMitsubishOutMobile from "../../../images/promo/PromoMitsubishiOutlanderMobile.png";
import PromoXpeng from "../../../images/promo/PromoXpeng.png";
import PromoXpengMobile from "../../../images/promo/PromoXpengMobile.png";

const dataSlider = (hidePartnered) => [
  {
    isTextImage: false,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: true,
    hideBanner: hidePartnered, // true will remove this section from the slider
    titleBadge: "",
    vehicleTitle: "",
    vehicleSubTitle: "",
    priceDollar: "",
    priceCents: "",
    period: "",
    disclaimerText: "",
    image: "",
    imageMobile: "",
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive offer NLA only",
    vehicleTitle: "Mitsubishi Outlander range",
    vehicleSubTitle: "Additional Discounts of up to $4,000 on top of National Fleet Discounts.",
    priceDollar: "218",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Discount levels ranging based on Outlander variant.",
    termsLinkUrl: "",
    image: PromoMitsubishOut,
    imageMobile: PromoMitsubishOutMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive offer NLA only",
    vehicleTitle: "XPENG G6",
    vehicleSubTitle: "Extended Warranty & $500 Accessories Credit. Exclusive to NLA Customers Only.",
    priceDollar: "199",
    priceCents: "",
    period: "weekly*",
    disclaimerText: "",
    termsLinkUrl: "https://www.novatedleaseaustralia.com.au/cars/xpeng/g6",
    image: PromoXpeng,
    imageMobile: PromoXpengMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Popular New Model",
    vehicleTitle: "BYD Shark GL",
    vehicleSubTitle: "Be one of the first in Australia to drive the BYD Shark.",
    priceDollar: "298",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.",
    termsLinkUrl: "",
    image: PromoBYDShark,
    imageMobile: PromoBYDSharkMobile,
  },
  {
    isTextImage: false,
    isTextImageNoPrice: true,
    isFullImage: false,
    isPartner: false,
    hideBanner: true, // true will remove this section from the slider
    titleBadge: "Exclusive for novated leases",
    vehicleTitle1: "$3,500 OFF",
    vehicleTitle2: "Tesla model 3 when purchased through a novated lease",
    vehicleSubTitle: "Plus $4,000 OFF current inventory stock of the Tesla Model Y",
    disclaimerText:
      `Purchase an eligible Tesla Model 3 by way of novated lease or purchase a Model Y from inventory stock, to qualify for these discounts. Offers both valid until 31st March 2025 and provided by Tesla.`,
    termsLinkUrl: "https://www.tesla.com/en_au/support/financing",  
    image: PromoTesla,
    imageMobile: PromoTeslaMobile,
  },
];

export default dataSlider;
