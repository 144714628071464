import React, { useState, useEffect } from "react";
import * as Styled from "./MainHeader.styled";
import { ButtonPurple } from "../../elements/Buttons";
import { FiPhone } from "react-icons/fi";
import { IoMenu } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";

import "./styles.css";

function Header({ isTablet, toggleDrawer, companyLogo, companyName }) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (isTablet) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;

        // Sticky behavior
        const headerNav = document.getElementById("header-nav");
        if (headerNav) {
          const sticky = headerNav.offsetTop;
          setIsSticky(scrollPosition > sticky);
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Cleanup function to remove event listener
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isTablet]);

  return (
    <>
      <Styled.MainWrapper
        id={isTablet ? "header-nav" : ""}
        className={isSticky ? "sticky" : ""}
      >
        <Styled.PartnerLogoWrapper>
          <Styled.LogoHeader />
          {companyLogo && (
            <>
              <Styled.PartnerLogoImg src={companyLogo} alt={companyName} />
              <Styled.PartnerLogoText>
                {companyName} have partnered with NLA to help you save!
              </Styled.PartnerLogoText>
            </>
          )}
        </Styled.PartnerLogoWrapper>

        {isTablet ? (
          <Styled.MobileRightWrapper>
            <Styled.PhoneNumberMobile href="tel:1300920773">
              <FiPhone />
            </Styled.PhoneNumberMobile>
            <button onClick={SendUTMQueryParams}>
              <FaRegCalendarAlt />
            </button>
            <button onClick={toggleDrawer("right", true)}>
              <IoMenu />
            </button>
          </Styled.MobileRightWrapper>
        ) : (
          <Styled.HeaderRightWrapper>
            <Styled.PhoneWrapper>
              <FiPhone />
              <Styled.PhoneNumber href="tel:1300920773">
                1300 920 773
              </Styled.PhoneNumber>
            </Styled.PhoneWrapper>
            <ButtonPurple
              onClick={SendUTMQueryParams}
              labelCTA="Get a quote"
            />
          </Styled.HeaderRightWrapper>
        )}
      </Styled.MainWrapper>
    </>
  );
}

export default Header;
