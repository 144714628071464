const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const SendUTMQueryParams = () => {
  const companySettings = JSON.parse(
    localStorage.getItem("nlaPortalTokenExp")
  );

  // format company name
  const companyName = toTitleCase(companySettings.companyName);

  // verify if key has been created on localStorage
  if (companySettings) {
    const url = `https://www.novatedleaseaustralia.com.au/quotes?utm_source=Steer&utm_medium=portal&email=${companySettings.employeeEmail}&company=${companyName}`;
    window.open(url, "_blank");
  }
}

export default SendUTMQueryParams;
