export const Vehicles = [
  {
    nvicCode: "0FMN25",
    name: "TESLA MODEL 3",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "SEDAN",
    fuelConsumption: "0",
    range: "629km",
    weeklyPrice: "209",
    cardColor: "green",
  },
  {
    nvicCode: "0FMT25",
    name: "TESLA MODEL Y",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "WAGON",
    fuelConsumption: "0",
    range: "510km",
    weeklyPrice: "212",
    cardColor: "green",
  },
  {
    nvicCode: "0F2H25",
    name: "BYD SEALION 6",
    engineDesc: "PETROL UNLEADED ULP",
    transmission: "AUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "92km",
    weeklyPrice: "191",
    cardColor: "green",
  },
  {
    nvicCode: "0E2925",
    name: "BYD SEAL",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "SEDAN",
    fuelConsumption: "0",
    range: "510km",
    weeklyPrice: "191",
    cardColor: "green",
  },
  {
    nvicCode: "0EWN25",
    name: "MG MG4",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "HATCHBACK",
    range: "405km",
    fuelConsumption: "0",
    weeklyPrice: "154",
    cardColor: "green",
  },
  {
    nvicCode: "0DII25",
    name: "MERCEDES-BENZ EQB",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "SUV",
    fuelConsumption: "0",
    range: "564km",
    weeklyPrice: "301",
    cardColor: "green",
  },
  {
    nvicCode: "0CGP25",
    name: "BMW iX1",
    engineDesc: "PETROL - PREMIUM ULP",
    transmission: "SPORTS AUTOMATIC",
    body: "SUV",
    fuelConsumption: "6.5 L/100km",
    range: "0",
    weeklyPrice: "333",
    cardColor: "green",
  },
  {
    nvicCode: "0FQW25",
    name: "BYD SHARK 6",
    engineDesc: "PETROL - PREMIUM ULP",
    transmission: "AUTOMATIC",
    body: "UTE",
    range: "0",
    fuelConsumption: "100KM",
    weeklyPrice: "220",
    cardColor: "green",
  },
  {
    nvicCode: "0FOT25",
    name: "VOLVO XC60",
    engineDesc: "PETROL - PREMIUM ULP",
    transmission: "SPORTS AUTOMATIC",
    body: "SUV",
    fuelConsumption: "81km",
    range: "0",
    weeklyPrice: "453",
    cardColor: "green",
  },
  {
    nvicCode: "0FPF25",
    name: "KIA EV5",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "SUV",
    fuelConsumption: "0",
    range: "-",
    weeklyPrice: "208",
    cardColor: "green",
  },

];
