import React from "react";
import * as Styled from "./Top10LeasedCarousel.styled";
import { ButtonOrange } from "../../elements/Buttons";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";
import { Vehicles } from "./Top10Data";
import Top10VehiclesSlide from "../Top10Vehicle";

function Top10LeasedCarousel({isTablet}) {
  return (
    <Styled.Top10LeasedWrapper>
      <Styled.MaxWidth>
        <Styled.TitleWrapper>
          <Styled.Title>
            Top 10 leased vehicles
          </Styled.Title>
          <Styled.ContentText>
              Our expert consultants can also answer any questions, so you can
              sign up with confidence.
          </Styled.ContentText>
          <ButtonOrange onClick={SendUTMQueryParams} labelCTA="Enquire now!" />
        </Styled.TitleWrapper>
        <Top10VehiclesSlide Vehicles={Vehicles} isTablet={isTablet} />
      </Styled.MaxWidth>
    </Styled.Top10LeasedWrapper>
  );
}

export default Top10LeasedCarousel;
