import { getCodeUrl, submitCodeUrl, submitSignupUrl, X_NLA_API_KEY } from './variables';

export const fetchEmail = async (email) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-NLA-API-KEY": X_NLA_API_KEY,
  };
  let body = {
    email: email,
  };
  try {
    const response = await fetch(getCodeUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) { // email exists
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during fetch:", error);
  }
}

export const fetchCode = async (email, codeDigits, isSignup) => {
  const enteredCode = codeDigits.join("");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-NLA-API-KEY": X_NLA_API_KEY,
  };
  let body = {
    email: email,
    code: enteredCode,
    signup: isSignup,
  };
  try {
    const response = await fetch(submitCodeUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) { // code matches
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during fetch:", error);
  }
}

export const fetchSignup = async (email, companyId) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-NLA-API-KEY": X_NLA_API_KEY,
  };
  let body = {
    email: email,
    company: companyId,
  };
  try {
    const response = await fetch(submitSignupUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during fetch:", error);
    
  }
}