import React, { forwardRef } from "react";
import * as Styled from "./NovatedLeaseBenefits.styled";
import GridItems from "./GridItems";
import { ButtonOrange } from "../../elements/Buttons";
import SendUTMQueryParams from "../../elements/SendUTMQueryParams/SendUTMQueryParams";

const NovatedLeaseBenefits = forwardRef((props, ref) => {
  return (
    <Styled.MaxWidth ref={ref} id="benefits">
      <Styled.MainWrapper>
        <Styled.Title>Novated lease benefits</Styled.Title>
        <GridItems />
        <Styled.ButtonWrapper>
          <ButtonOrange onClick={SendUTMQueryParams} labelCTA="Get a quote" />
        </Styled.ButtonWrapper>
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
});

export default NovatedLeaseBenefits;
