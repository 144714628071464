export const Vehicles = [
  {
    nvicCode: "0FQH25",
    name: "FORD RANGER",
    engineDesc: "DIESEL",
    transmission: "SPORTS AUTOMATIC",
    body: "UTE",
    range: "0",
    fuelConsumption: "7.2 L/100km",
    weeklyPrice: "306",
    cardColor: "gray",
  },
  {
    nvicCode: "0FMT25",
    name: "TESLA MODEL Y",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "WAGON",
    fuelConsumption: "0",
    range: "510km",
    weeklyPrice: "212",
    cardColor: "gray",
  },
  {
    nvicCode: "0E0I25",
    name: "MITSUBISHI OUTLANDER PHEV",
    engineDesc: "PETROL - PREMIUM ULP",
    transmission: "AUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "84km",
    weeklyPrice: "216",
    cardColor: "gray",
  },
  {
    nvicCode: "0FQW25",
    name: "BYD SHARK 6",
    engineDesc: "PETROL - PREMIUM ULP",
    transmission: "AUTOMATIC",
    body: "UTE",
    range: "0",
    fuelConsumption: "100KM",
    weeklyPrice: "220",
    cardColor: "gray",
  },
  {
    nvicCode: "0FMN25",
    name: "TESLA MODEL 3",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "SEDAN",
    fuelConsumption: "0",
    range: "629km",
    weeklyPrice: "209",
    cardColor: "gray",
  },
  {
    nvicCode: "0EWN25",
    name: "MG MG4",
    engineDesc: "ELECTRIC",
    transmission: "REDUCTION GEAR",
    body: "HATCHBACK",
    range: "405km",
    fuelConsumption: "0",
    weeklyPrice: "154",
    cardColor: "gray",
  },
  {
    nvicCode: "09PW25",
    name: "TOYOTA LANDCRUISER",
    engineDesc: "DIESEL",
    transmission: "SPORTSAUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "7.6 L/100km",
    weeklyPrice: "388",
    cardColor: "gray",
  },
  {
    nvicCode: "0EJ025",
    name: "FORD EVEREST",
    engineDesc: "DIESEL",
    transmission: "SPORTS AUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "7.1 L/100km",
    weeklyPrice: "305",
    cardColor: "gray",
  },
  {
    nvicCode: "0EXW25",
    name: "ISUZU MU-X",
    engineDesc: "DIESEL",
    transmission: "SPORTS AUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "7.4 L/100km",
    weeklyPrice: "278",
    cardColor: "gray",
  },
  {
    nvicCode: "0F2H25",
    name: "BYD SEALION 6",
    engineDesc: "PETROL UNLEADED ULP",
    transmission: "AUTOMATIC",
    body: "SUV",
    range: "0",
    fuelConsumption: "92km",
    weeklyPrice: "191",
    cardColor: "gray",
  }
];
